import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import AppButton from "./AppButton"
import { image, individual } from "../styles/about.module.css"

const About = ({ isMobile }) => {
  const isBrowser = typeof window !== "undefined"
  return (
    <div
      className={`flex flex-col md:py-8 xs:py-6  px-4 sm:px-8 md:px-12 lg:px-16`}
    >
      <div
        className={`md:grid md:grid-cols-2 items-center md:self-end md:mb-28 xs:mb-8 ${individual}`}
        id="acm"
        style={{ marginTop: "-100px", paddingTop: "100px" }}
      >
        <div className="md:block xs:hidden relative">
          <StaticImage src="../images/acm.png" className={`${image} z-100`} />
          <img
            src="/about_1.png"
            style={{
              position: "absolute",
              width: "145.45px",
              height: "150.24px",
              left: "-47.54px",
              top: "0px",

              /* ACM Blue/ACMB_100 */

              // background: "#80CAEC",
              opacity: "0.7",
              zIndex: "-100",
            }}
            alt="about"
          />
          <img
            src="/about_2.png"
            style={{
              position: "absolute",
              width: "187.26px",
              height: "179.44px",
              left: "129.46px",
              top: "176px",

              /* ACM Blue/ACMB_100 */

              // background: "#80CAEC",
              opacity: "0.7",
              zIndex: "-100",
            }}
            alt="about"
          />
        </div>
        <div>
          <div className={`flex items-center justify-between mb-6`}>
            <p
              className={`heading`}
            >
              ACM
            </p>
            <div className={`md:hidden`}>
              <StaticImage src="../images/acm.png" className={`${image}`} />
            </div>
          </div>
          <p
            className={`${
              isMobile ? "paragraph2 regular" : "paragraph1 medium"
            } mb-4 text-justify md:text-left`}
          >
            ACM is the world's largest educational and scientific computing
            society, aimed at increasing the level and visibility of ACM
            activities across the globe. ACM is a society of dedicated
            professionals, academicians, and research scientists, who contribute
            towards the growth of technological innovations. The purpose of
            Society is to inculcate computational thinking aiding in career
            development and a lifelong learning experience.
          </p>
          <AppButton
            variant="tertiary"
            size={isMobile ? "mobile" : "large"}
            title="Learn More"
            style={{color: "#134467"}}
            onClick={()=> {
              if(isBrowser) {
                window?.open("https://india.acm.org/")
              }
            }}
          ></AppButton>
        </div>
      </div>
      <div
        className={`md:grid md:grid-cols-2 items-center md:self-start ${individual}`}
        id="pasc"
        style={{ marginTop: "-100px", paddingTop: "100px" }}
      >
        <div>
          <div className={`flex items-center justify-between mb-6`}>
            <p
              className={`heading`}
            >
              PASC
            </p>
            <div className={`md:hidden`}>
              <StaticImage src="../images/logo.png" style={{height: "48px", width: "79px"}} />
            </div>
          </div>
          <p
            className={`${
              isMobile ? "paragraph2 regular" : "paragraph1 medium"
            } mb-4 text-justify md:text-left`}
          >
            PICT ACM Student Chapter is an auxiliary organization of ACM.
            ACM-PICT is one of the most reputed and distinguished technical
            chapters working with a vision and determination since its inception
            in 2011, with an aspiration of advancement in Computer Science as a
            science and a profession. We encourage co-curricular activities
            amongst the members, which helps in their overall development and
            transform students into responsible adults with a strong core.
          </p>
        </div>
        <div className="md:block xs:hidden justify-self-end relative">
          <StaticImage src="../images/logo.png" />
          <img
            src="/about_3.png"
            style={{
              position: "absolute",
              width: "185.67px",
              height: "187.89px",
              left: "-80.54px",
              top: "-55px",

              /* ACM Blue/ACMB_100 */

              // background: "#80CAEC",
              opacity: "0.7",
              zIndex: "-100",
            }}
            alt="about"
          />
          <img
            src="/about_4.png"
            style={{
              position: "absolute",
              width: "165.1px",
              height: "159.42",
              left: "219.46px",
              top: "36px",

              /* ACM Blue/ACMB_100 */

              // background: "#80CAEC",
              opacity: "0.7",
              zIndex: "-100",
            }}
            alt="about"
          />
        </div>
      </div>
    </div>
  )
}

export default About
