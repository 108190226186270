import React from "react"
import { icon_small } from "../styles/social.module.css"

const ContactForSponsors = ({ isMobile }) => {
  const legends = [
    {
      name: "Om Lachure",
      position: "Marketing Head",
      email: "omlachure@gmail.com",
    },
    {
      name: "Rohit Kuvar",
      position: "Public Relations Officer",
      email: "rkvijay221@gmail.com",
    },
  ]

  return (
    <div>
      <p
        className={`subHeading md:mb-6 xs:mb-4`}
      >
        Contact for sponsorship
      </p>
      <div className={`grid grid-cols-2`}>
        {legends.map(legend => (
          <div key={legend.name}>
            <p className={`paragraph1 ${isMobile ? "regular" : "medium"}`}>
              {legend.name}
            </p>
            <p className={`paragraph3 light`}>{legend.position}</p>
            <a
              href={`mailto:${legend.email}`}
              target="_blank"
              rel="noreferrer"
              className={`flex items-center md:mt-2 xs:mt-1`}
            >
              <span className={`material-icons ${icon_small} mr-2`}>email</span>
              <p className={`paragraph3 ${isMobile ? "regular" : "medium"}`}>
                {legend.email}
              </p>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ContactForSponsors
