import React from "react"
import { icon, map } from "../styles/social.module.css"

const ReachUs = ({ isMobile }) => {
  return (
    <div>
      <p
        className={`subHeading md:mb-6 xs:mb-4`}
      >
        Reach Us
      </p>
      <div className={`flex items-center md:mb-6 xs:mb-4`}>
        <span className={`material-icons ${icon} mr-2`}>email</span>
        <p
          className={`${isMobile ? "paragraph2 regular" : "paragraph2 medium"}`}
        >
          acm.pict@gmail.com
        </p>
      </div>
      <div className={`flex items-center md:mb-6 xs:mb-4`}>
        <span className={`material-icons ${icon} mr-2`}>location_on</span>
        <p
          className={`${isMobile ? "paragraph2 regular" : "paragraph2 medium"}`}
        >
          Survey No. 27, Near Trimurti Chowk, Dhankawadi, Pune - 411043
        </p>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.5743358467566!2d73.84935001493757!3d18.45762617591354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eac859c63c21%3A0xa131e8fd4e2bd999!2z4KSq4KWB4KSj4KWHIOCkh-CkguCkuOCljeCkn-Ckv-Ckn-CljeCkr-ClguCknyDgpJHgpKvgpLwg4KSV4KSC4KSq4KWN4KSv4KWC4KSf4KSwIOCkn-Clh-CkleCljeCkqOCli-CksuClieCknOClgCDgpIXgpKrgpY3gpLLgpL7gpIfgpKEg4KS44KS-4KSH4KSC4KS4IOCkoeCkv-CkquCkvuCksOCljeCkn-CkruClh-CkqOCljeCknw!5e0!3m2!1shi!2sin!4v1629127296715!5m2!1shi!2sin"
        width={`${isMobile ? "100%" : "90%"}`}
        height={`${isMobile ? "180" : "256"}`}
        allowfullscreen
        loading="lazy"
        title="Location"
        className={`${map}`}
      ></iframe>
    </div>
  )
}

export default ReachUs
